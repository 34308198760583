<template>
  <div
    v-if="article"
    class="add-new-item-admin"
  >
    <title-bar
      submitBtn
      :submitBtnTitle="$t('messages.articleUpdate')"
      :title="$t('messages.articleUpdate')"
      @submitPressed="submitPressed"
    />
    <page-form :page-obj="article" :action="action" repository="articles" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import PageForm from '@/views/Admin/Pages/components/PageForm.vue';

export default {
  components: {
    TitleBar,
    PageForm
  },
  data() {
    return {
      action: null,
      article: null
    }
  },
  created() {
    this.loadArticle()
  },
  methods: {
    loadArticle() {
      const id = this.$route.params.id;
      this.$Articles.getResource({ id }).then((response) => {
        this.article = response.data
        this.article.date = new Date(response.data.date)
        
        
      });
    },
    submitPressed() {
      this.action = 'update'
    }
  }
};
</script>

<style lang="scss">
</style>
